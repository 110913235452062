import React from 'react';
import { Grid, Container, Paper } from '@material-ui/core';
import { Button, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

const PayinReportInvoice = ({data}) => {
    const userID = localStorage.getItem('userId');


    if (!data) return null;

    console.log('Generating PDF for transaction:', data.txnid);

    return (
        <Container 
            style={{ 
                padding: '1rem', 
                marginTop: 'calc(3% + 56px)', 
                width: '100%', 
                maxWidth: '1000px', // Adjusted width to fit landscape PDF
                boxSizing: 'border-box' 
            }}
        >
            <Grid>
                <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography style={{ fontWeight: 'bold', fontSize: '32px' }}>iPaisa</Typography>
                    <Typography style={{ fontWeight:"bold", fontSize:"20px" }}>EDSOM FINTECH PRIVATE LIMITED</Typography>
                </Grid>
                <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Grid style={{ marginTop: '1%' }}>
                        <Typography style={{ fontSize: '14px', fontWeight: 'bold', textAlign:"start" }}>
                            {new Date(data.addedon).toLocaleDateString() || 'N/A'}
                        </Typography>
                        <Typography style={{ fontSize: '14px', fontWeight: 'bold', textAlign:"start" }}>
                        {new Date(data.addedon).toLocaleTimeString() || 'N/A'}
                        </Typography>
                    </Grid>
                    <Grid>
                        <Typography style={{ fontSize: '14px', textAlign:"end" }}>
                            201, BANER ROAD, PUNE, MAHARASHTRA - 411045
                        </Typography>
                        <Typography style={{ fontSize: '14px', textAlign:"end" }}>
                            edsomfintech@gmail.com
                        </Typography>
                        <Typography style={{ fontSize: '14px', textAlign:"end", fontWeight:'bold' }}>
                            UserID : {userID}
                        </Typography>
                    </Grid>
                </Grid>
                <TableContainer style={{ marginTop: '3%' }}>
                    <Table aria-label="transaction table" style={{ tableLayout: 'fixed', width: '100%' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight:"bold", width: '10%', fontSize:'10px'}}>Txn ID</TableCell>
                                <TableCell style={{ fontWeight:"bold", width: '10%', fontSize:'10px'}}>UTR No.</TableCell>
                                <TableCell style={{ fontWeight:"bold", width: '10%', fontSize:'10px'}}>Amount</TableCell>
                                <TableCell style={{ fontWeight:"bold", width: '10%', fontSize:'10px'}}>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        <TableRow>
                                <TableCell style={{fontSize:'10px'}}>{data.txnid || 'N/A'}</TableCell>
                                <TableCell style={{fontSize:'10px'}}>{data.easepayid || 'N/A'}</TableCell>
                                <TableCell style={{fontSize:'10px'}}>{data.amount || 'N/A'}</TableCell>
                                <TableCell style={{fontSize:'10px'}}>
                                    {data.status === 'Transaction Successful' ? 'Successful'
                                        : data.status === 'Transaction Pending' ? 'Pending'
                                            : data.status === 'REFUND' ? 'Refund'
                                                : data.status === 'Transaction Failed' ? 'Failed'
                                                    : data.status || 'N/A'} {/* Default value if no match */}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Grid style={{ marginTop:'4%', display:'flex', alignItems:'center', justifyContent:'space-between' }}>
                    <Grid>
                        <Typography style={{ fontWeight:'bold', fontSize:'24px' }}>
                            Thank you for transacting at iPaisa!
                        </Typography>
                        <Typography color='textSecondary'>
                            This is a system generated receipt hence does not require any signature.
                        </Typography>
                        <Typography color='textSecondary'>
                            Queries? Write to care@ipaisa.co.in
                        </Typography>
                    </Grid>
                    <Grid>
                        <Divider style={{ width:'100%' }} />
                        <Grid>
                            <Typography>Total Amount : {data.amount}</Typography>
                            <Typography style={{ fontWeight:'bold' }}>Grand Total : {data.amount}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

export default PayinReportInvoice
