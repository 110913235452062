import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, TextField, Button, MenuItem, Grid, Card, CardContent, Paper, CircularProgress, Container } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import WalletBalanceComponent from './WalletBalanceComponent';
import { PartnerArrowIcon } from '../../res/NewWebIcons';
import InsufficiantB from './InsufficiantB';
import PayoutPopup from './PayoutPopup';
import PayoutFailedPopup from './PayoutFailedPopup';
import BulkPayoutSucPop from './BulkPayoutSucPop';
import BulkPayoutFailed from './BulkPayoutFailed';
import { useNavigate } from 'react-router-dom';
import { fetchWalletBalance } from '../../redux/actions/walletActions';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBankDetails } from '../../redux/actions/bankActions';
import { initiatePayout } from '../../redux/actions/payoutActions';
import { initiateBulkPayout } from '../../redux/actions/bulkPayoutActions';
import IpinModal from '../IpaisaMpin/IpinModal';
import SetPasswordModal from "../IpaisaMpin/SetIPMpin";  // Import SetPassword modal


const payoutModes = [
    { value: 'NEFT', label: 'NEFT' },
    { value: 'RTGS', label: 'RTGS' },
    { value: 'IMPS', label: 'IMPS' },
    // Add more options as needed
];

const PayoutSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    payeeaccountNumber: Yup.string().required('Payee Account Number is required'),
    bankIfsc: Yup.string().required('Bank IFSC is required'),
    transferMode: Yup.string().required('Transfer Mode is required'),
    transferAmount: Yup.number().required('Transfer Amount is required').positive('Amount must be positive'),
    remarks: Yup.string(),
    // purpose: Yup.string(),
});

const BulkPayoutSchema = Yup.object().shape({
    file: Yup.mixed().required('File is required'),
});

const NewPayout = () => {


    const [showBulkPayout, setShowBulkPayout] = useState(false);
    const bulkPayout = localStorage.getItem("bulkPayout") === 'true';
    const [showSuccessPop, setShowSuccessPop] = useState(false);
    const [showFailedPop, setShowFailedPop] = useState(false);
    const [insufficiantB, setInsufficiantB] = useState(false);
    const [bulkPayoutSuccess, setBulkPayoutSuccess] = useState(false);
    const [bulkPayoutFailed, setBulkPayoutFailed] = useState(false);
    // const [isIpinModalOpen, setIpinModalOpen] = useState(false);
    const [insufficientB, setInsufficientB] = useState(false);
    const [pendingSubmission, setPendingSubmission] = useState(null); // Store form values to submit after IPIN
    const [loading, setLoading] = useState(false);
    const [isBulkPayoutReady, setIsBulkPayoutReady] = useState(false);
    const [openSetPasswordModal, setOpenSetPasswordModal] = useState(false); // Controls SetPassword modal
    const [isIpinModalOpen, setIpinModalOpen] = useState(false);  // Controls IPIN modal
    const navigate = useNavigate();
    const handleClose = () => {
        setShowSuccessPop(false);
        setShowFailedPop(false);
        setInsufficiantB(false);
        setBulkPayoutSuccess(false);
        setBulkPayoutFailed(false);
    };
    const walletBalanceFromStore = useSelector((state) => state.wallet.balance);
    const [walletBalance, setWalletBalance] = useState(walletBalanceFromStore);
    const bankDetails = useSelector((state) => state.bank.bankDetails);
    const dispatch = useDispatch();

    useEffect(() => {
        console.log('Wallet balance from store:', walletBalanceFromStore); // Log the balance from store
        setWalletBalance(walletBalanceFromStore);
    }, [walletBalanceFromStore]);
    useEffect(() => {
        dispatch(fetchWalletBalance());
        dispatch(fetchBankDetails());// Dispatch action to fetch wallet balance
    }, [dispatch]);

    const handleCloseModal = () => {
        setIpinModalOpen(false);
        setOpenSetPasswordModal(false);  // Close SetPassword modal
    };
    const getLocation = async () => {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => resolve(position.coords),
                    (error) => reject(error)
                );
            } else {
                reject(new Error('Geolocation is not supported by this browser.'));
            }
        });
    };

    const handlePayClick = (values) => {
        setPendingSubmission(values); // Store form values for later submission

        if (localStorage.getItem("isTxnMpinSet") === "False") {
            setOpenSetPasswordModal(true); // Open SetPassword modal if MPIN is not set
        } else {
            setIpinModalOpen(true); // Open IPIN modal if MPIN is set
        }
    };
    // This function is called once the IPIN is validated.
    const handleIpinValid = async (ipin) => {
        console.log('Submitted IPIN:', ipin);
        setIpinModalOpen(false); // Close the IPIN modal

        // Proceed with bulk payout submission after IPIN validation only if there's a pending submission
        if (pendingSubmission) {
            await handleSubmitAfterIpin(pendingSubmission); // Call the submit function directly
            setPendingSubmission(null); // Clear pending submission after processing
        }
    };

    // This function handles the submission after IPIN is validated
    const handleSubmitAfterIpin = async (values) => {
        setLoading(true);

        // Check wallet balance
        if (values.transferAmount > walletBalanceFromStore) {
            toast.error("Insufficient wallet balance.");
            setInsufficientB(true);
            setLoading(false); // Stop loading
            return;
        }

        try {
            const location = await getLocation();
            const payload = {
                ...values,
                payeraccountNumber: '120664700000021', // Example payer account number
                latitude: location.latitude.toFixed(4),
                longitude: location.longitude.toFixed(4),
            };

            // API Call for payout
            const token = localStorage.getItem('jwtToken');
            const response = await axios.post(
                'https://api.ipaisa.co.in/myapp/auth/instantpay/payout',
                payload,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            // Handle the response status
            if (response.status === 200) {
                toast.success("Payout initiated successfully.");
                setShowSuccessPop(true);
                dispatch(fetchWalletBalance()); // Refresh wallet balance after successful payout
            } else if (response.status === 402) {
                toast.error("Insufficient funds for payout.");
                setInsufficientB(true);
            } else {
                toast.error("Payout initiation failed.");
                setShowFailedPop(true);
            }
        } catch (error) {
            console.error('Payout failed:', error);
            toast.error("An error occurred: " + error.message);
            setShowFailedPop(true);
        } finally {
            setLoading(false); // Stop loading regardless of the outcome
        }
    };

    // This function handles the form submission and opens the IPIN modal
    const handleSubmit = async (values, { setSubmitting }) => {
        // Check if IPIN validation is needed
        if (!isIpinModalOpen) {
            setPendingSubmission(values); // Store form values for later use
            setIpinModalOpen(true); // Open the IPIN modal for validation
        }
        setSubmitting(false); // Prevent further form submission until IPIN is validated
    };


    const handleBulkSubmit = async (values, { setSubmitting }) => {
        const file = values.file;

        if (!file) {
            toast.error('No file selected');
            setSubmitting(false);
            return;
        }

        try {
            const workbook = XLSX.read(await file.arrayBuffer(), { type: 'array' });
            const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(firstSheet);

            // Convert JSON data to CSV format
            const csvString = Papa.unparse(jsonData);
            const newCsvFile = new Blob([csvString], { type: 'text/csv' });
            const formData = new FormData();
            formData.append('file', newCsvFile, 'processed_payout.csv');

            const token = localStorage.getItem('jwtToken');
            const response = await fetch('https://api.ipaisa.co.in/myapp/auth/launchjob', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            const totalAmount = jsonData.reduce((sum, item) => sum + parseFloat(item.amount || 0), 0); // Assuming the amount field exists
            if (totalAmount > walletBalance) {
                throw new Error('Insufficient wallet balance');
            }

            if (!response.ok) {
                throw new Error('Failed to submit bulk payout');
            }

            // Dispatch the fetchWalletBalance action after successful submission
            dispatch(fetchWalletBalance()); // Dispatch to update wallet balance
            toast.success('Bulk Payout successful');
        } catch (error) {
            console.error('Bulk Payout failed:', error);
            toast.error('Bulk Payout failed: ' + error.message);
        } finally {
            setSubmitting(false);
        }
    };

    const handleOpenModal = () => {
        setIpinModalOpen(true);
    };
    const BulkFileUpload = ({ setFieldValue }) => {
        const [selectedFile, setSelectedFile] = useState(null);
        const [error, setError] = useState('');

        const { getRootProps, getInputProps } = useDropzone({
            accept: '.xlsx',
            onDrop: (acceptedFiles, rejectedFiles) => {
                if (acceptedFiles.length > 0) {
                    const file = acceptedFiles[0];
                    setFieldValue('file', file);
                    setSelectedFile(file);
                    setError('');
                } else if (rejectedFiles.length > 0) {
                    setError('Only .xlsx files are allowed');
                }
            },
        });

        const handleRemoveFile = () => {
            setFieldValue('file', null);
            setSelectedFile(null);
            setError('');
        };

        return (
            <div>
                <div
                    {...getRootProps()}
                    style={{
                        border: '2px dashed #CCCCCC',
                        padding: '20px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        marginBottom: '16px',
                    }}
                >
                    <input {...getInputProps()} />
                    <Typography>Drag and Drop an .xlsx file here or click to select a file</Typography>
                </div>
                {error && (
                    <Typography color="error" variant="body2">
                        {error}
                    </Typography>
                )}
                {selectedFile && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <Typography>{selectedFile.name}</Typography>
                            <Button variant="outlined" color="error" onClick={handleRemoveFile}>
                                Remove
                            </Button>
                        </Box>
                    </Box>
                )}
            </div>
        );
    };




    const inputHeight = '56px';

    // const BulkFileUpload = ({ setFieldValue }) => {
    //     const [selectedFile, setSelectedFile] = useState(null);
    //     const [error, setError] = useState('');

    //     const { getRootProps, getInputProps } = useDropzone({
    //         accept: '.xlsx',
    //         onDrop: (acceptedFiles, rejectedFiles) => {
    //             if (acceptedFiles.length > 0) {
    //                 const file = acceptedFiles[0];
    //                 setFieldValue('file', file);
    //                 setSelectedFile(file);
    //                 setError('');
    //             } else if (rejectedFiles.length > 0) {
    //                 setError('Only .xlsx files are allowed');
    //             }
    //         },
    //     });

    //     const handleRemoveFile = () => {
    //         setFieldValue('file', null);
    //         setSelectedFile(null);
    //         setError('');
    //     };

    //     return (
    //         <div>
    //             <div
    //                 {...getRootProps()}
    //                 style={{
    //                     border: '2px dashed #CCCCCC',
    //                     padding: '20px',
    //                     textAlign: 'center',
    //                     cursor: 'pointer',
    //                     marginBottom: '16px',
    //                 }}
    //             >
    //                 <input {...getInputProps()} />
    //                 <Typography>Drag and Drop an .xlsx file here or click to select a file</Typography>
    //             </div>
    //             {error && (
    //                 <Typography color="error" variant="body2">
    //                     {error}
    //                 </Typography>
    //             )}
    //             {selectedFile && (
    //                 <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
    //                     <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    //                         <Typography>{selectedFile.name}</Typography>
    //                         <Button variant="outlined" color="error" onClick={handleRemoveFile}>
    //                             Remove
    //                         </Button>
    //                     </Box>
    //                 </Box>
    //             )}
    //         </div>
    //     );
    // };


    return (
        <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
            <Grid>
                <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
                    <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '13px' }}>Balance</Typography>
                    <PartnerArrowIcon />
                    <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '13px' }}>Pay Out</Typography>
                </Grid>

                <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1%' }}>
                    <Grid>
                        <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '20px', color: '#343C6A' }}>Pay Out</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: '3%' }}>
                {/* Wallet Balance Card */}
                <WalletBalanceComponent walletBalance={walletBalance} />

                <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
                    <Paper style={{ padding: '2rem', borderRadius: '8px' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mb: 2 }}>
                            <Button
                                onClick={() => setShowBulkPayout(false)}
                                style={{
                                    borderBottom: showBulkPayout ? 'none' : '2px solid #027BFE',
                                    textTransform: 'none',
                                    color: showBulkPayout ? '#808080' : '#027BFE',
                                    marginRight: '10px',
                                    fontSize: '16px'
                                }}
                            >
                                Payout
                            </Button>
                            {bulkPayout && (
                                <Button
                                    onClick={() => setShowBulkPayout(true)}
                                    style={{
                                        borderBottom: showBulkPayout ? '2px solid #027BFE' : 'none',
                                        textTransform: 'none',
                                        color: showBulkPayout ? '#027BFE' : '#808080',
                                        fontSize: '16px'
                                    }}
                                >
                                    Bulk Payout
                                </Button>
                            )}
                        </Box>

                        {bulkPayout && showBulkPayout ? (
                            <Formik
                                initialValues={{ file: null }}
                                onSubmit={handleBulkSubmit} // Ensure handleBulkSubmit is called correctly
                                validationSchema={BulkPayoutSchema}
                            >
                                {({ isSubmitting, setFieldValue, errors, touched }) => (
                                    <Form>
                                        <BulkFileUpload setFieldValue={setFieldValue} />
                                        {errors.file && touched.file ? (
                                            <div style={{ color: 'red' }}>{errors.file}</div>
                                        ) : null}
                                        <Box display="flex" justifyContent="end" gap={1} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                                            {/* Link to download the sample CSV file */}
                                            <Button
                                                sx={{
                                                    textTransform: 'none',
                                                    width: { xs: '100%', md: 'auto' },
                                                    fontSize: '16px',
                                                    borderRadius: '8px',
                                                }}
                                                component="a"
                                                href={`${process.env.PUBLIC_URL}/payoutsheet.xlsx`}
                                                download="sample.xlsx" // Update this if you are downloading a CSV file
                                            >
                                                Sample CSV
                                            </Button>


                                            <Button
                                                sx={{
                                                    border: '1.5px solid #FF0000',
                                                    color: '#FF0000',
                                                    textTransform: 'none',
                                                    width: { xs: '100%', md: '18%' },
                                                    fontSize: '16px',
                                                    borderRadius: '8px',
                                                    height: inputHeight,
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                type="submit"
                                                sx={{
                                                    backgroundColor: "#027BFE",
                                                    color: "white",
                                                    textTransform: "none",
                                                    width: { xs: '100%', md: '18%' },
                                                    fontSize: '16px',
                                                    borderRadius: '8px',
                                                    height: inputHeight,
                                                    '&:hover': {
                                                        backgroundColor: "#027BFE",
                                                        color: '#FFFFFF',
                                                    },
                                                }}
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Bulk Payout'}
                                            </Button>
                                        </Box>
                                    </Form>
                                )}
                            </Formik>
                        ) : (
                            <Formik
                                initialValues={{
                                    name: '',
                                    payeeaccountNumber: '',
                                    bankIfsc: '',
                                    transferMode: '',
                                    transferAmount: '',
                                    remarks: '',
                                    purpose: 'REIMBURSEMENT',
                                }}
                                validationSchema={PayoutSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ isSubmitting, errors, touched, setFieldValue, values }) => (
                                    <Form>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6} >
                                                <Field
                                                    as={TextField}
                                                    name="name"
                                                    label="Name"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                {bankDetails?.length > 0 ? (
                                                    <Field
                                                        as={TextField}
                                                        label="Account Number"
                                                        name="payeeaccountNumber"
                                                        select
                                                        fullWidth
                                                        variant="outlined"
                                                        error={touched.payeeaccountNumber && Boolean(errors.payeeaccountNumber)}
                                                        helperText={touched.payeeaccountNumber && errors.payeeaccountNumber}
                                                        onChange={(event) => {
                                                            const selectedAccountNumber = event.target.value;
                                                            setFieldValue('payeeaccountNumber', selectedAccountNumber);

                                                            const selectedBankDetail = bankDetails.find(
                                                                (bankDetail) => bankDetail.accountNumber === selectedAccountNumber
                                                            );

                                                            if (selectedBankDetail) {
                                                                setFieldValue('bankIfsc', selectedBankDetail.ifsc);
                                                                setFieldValue('name', selectedBankDetail.name);
                                                                // Add any other fields you want to auto-fill here
                                                            }
                                                        }}
                                                    >
                                                        {bankDetails.map((bankDetail) => (
                                                            <MenuItem
                                                                key={bankDetail.accountNumber}
                                                                value={bankDetail.accountNumber}
                                                            >
                                                                {`${bankDetail.accountNumber} - ${bankDetail.name}`}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                ) : (
                                                    <Typography variant="body1" color="error" style={{ marginTop: '16px' }}>
                                                        No bank details added
                                                    </Typography>
                                                )}
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Field
                                                    as={TextField}
                                                    name="bankIfsc"
                                                    label="Bank IFSC"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Field
                                                    as={TextField}
                                                    name="transferMode"
                                                    label="Transfer Mode"
                                                    select
                                                    variant="outlined"
                                                    fullWidth
                                                >
                                                    {payoutModes.map((mode) => (
                                                        <MenuItem key={mode.value} value={mode.value}>
                                                            {mode.label}
                                                        </MenuItem>
                                                    ))}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Field
                                                    as={TextField}
                                                    name="transferAmount"
                                                    label="Transfer Amount"
                                                    variant="outlined"
                                                    type="number"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Field
                                                    as={TextField}
                                                    name="remarks"
                                                    label="Remarks"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} style={{ marginTop: '3%' }}>
                                            <Box display="flex" justifyContent="end" gap={1} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                                                <Button
                                                    sx={{
                                                        border: '1.5px solid #FF0000',
                                                        color: '#FF0000',
                                                        textTransform: "none",
                                                        width: { xs: '100%', md: '18%' },
                                                        fontSize: '16px',
                                                        borderRadius: '8px',
                                                        height: inputHeight
                                                    }}>Cancel</Button>
                                                <Button
                                                    type="submit"
                                                    onClick={() => handlePayClick(values)}
                                                    sx={{
                                                        backgroundColor: "#027BFE",
                                                        color: "white",
                                                        textTransform: "none",
                                                        width: { xs: '100%', md: '18%' },
                                                        fontSize: '16px',
                                                        borderRadius: '8px',
                                                        height: inputHeight,
                                                        '&:hover': {
                                                            backgroundColor: "#027BFE",
                                                            color: '#FFFFFF',
                                                        },
                                                    }}
                                                    disabled={loading}                                                 >
                                                    {loading ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Pay'}

                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        )}
                    </Paper>
                </Grid>
            </Grid>
            {showSuccessPop && <PayoutPopup handleClose={handleClose} />}
            {showFailedPop && <PayoutFailedPopup handleClose={handleClose} />}
            {insufficiantB && <InsufficiantB handleClose={handleClose} />}
            {bulkPayoutSuccess && <BulkPayoutSucPop handleClose={handleClose} />}
            {bulkPayoutFailed && <BulkPayoutFailed handleClose={handleClose} />}
            {localStorage.getItem("isTxnMpinSet") === "False" ? (
                <SetPasswordModal
                    open={openSetPasswordModal}
                    onClose={() => setOpenSetPasswordModal(false)}
                />
            ) : (
                <IpinModal
                    open={isIpinModalOpen}
                    onClose={handleCloseModal}
                    onMpinValid={handleIpinValid}
                />
            )}
        </Container>
    )
}

export default NewPayout
