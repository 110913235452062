import React, { useState } from "react";
import {
  Button,
  TextField,
  Container,
  Grid,
  Typography,
  Box,
  MenuItem,
  CardMedia,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import logo from "../Assets/login/iPaisaLogo.jpg";
import BBPSLogo from "../Assets/BBPSLogo/NewBBPS.png";
import { NewBBPSIcon } from "../../res/NewWebIcons";



export default function REducationFees() {
  const [selectedLocation, setSelectedLocation] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const instituteData = {
    Maharashtra: [
      "Amity University, Mumbai",
      "Navi Mumbai, Maharashtra",
      "Pimpri Chinchwad University, Pune",
      "Vidyashilp University, Bengaluru",
      "Bharati Vidyapeeth, Pune",
      "MIT-WPU Pune - Dr Vishwanath Karad MIT World Peace University, Pune",
      "Somaiya Vidyavihar University, Mumbai"
    ],
    Gujarat: [
      "Indian Institute of Management, Ahmedabad",
      "Gujarat University, Ahmedabad",
      "Gujarat National Law University, Gandhinagar",
      "Indian Institute Of Technology, Gandhinagar",
      "Sardar Vallabhbhai Patel National Institute Of Technology, Surat",
      "Ahmedabad University, Ahmedabad"
    ]
    // Add institutes for other states here
  };

  const formik = useFormik({
    initialValues: {
      institute: "",
      studentId: "",
    },
    validationSchema: Yup.object({
      institute: Yup.string().required("Institute is required"),
      studentId: Yup.string().required("Student ID is required"),
    }),
    onSubmit: (values) => {
      // Perform form submission logic here
      setSubmitSuccess(true);
    },
  });

  const handleLocationChange = (event) => {
    const selectedLocation = event.target.value;
    setSelectedLocation(selectedLocation);
  };

  const inputHeight = '56px'; 


  return (
    <Container style={{marginTop:'12%', marginBottom:'3%'}}>
      <Box mt={4} p={4} boxShadow={2} bgcolor="#DDF1F5" borderRadius={8}
      sx={{
        background: 'linear-gradient(135deg, #0B1E59 0%, #1840BF 100%)',
        color: 'white',
      }}>
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          style={{ marginTop: "20px" }}
        ></Typography>
        <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
        <CardMedia
              component="img"
              image={logo}
              alt="iPaisa Logo"
              style={{ backgroundColor: "transparent", borderRadius: "50%", marginBottom:'3%' }}
              sx={{
                height: { xs: 50, md: 90 },
                width: { xs: 50, md: 90 },
                mt: { xs: 1, md: 1 },
                marginRight: { xs: "10px", md: "10px" }, // Adjusted to ensure space between logo and text
              }}
            /> 
            <Typography variant="h4" sx={{ fontWeight: 'bold', fontSize:'30px' }}>
              Choose Your Institute
            </Typography>
            <Typography variant="body1" paragraph  sx={{fontSize:'20px', marginTop:'3%'}}>
              Select your institute location and institute to proceed.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box p={4} bgcolor="white" boxShadow={2} borderRadius={8}>
              <Typography variant="h4" color= "#027BFE" sx={{ fontWeight: 'bold', fontSize:'30px'}}>
                Education Fees Payment
              </Typography>
              <Box display="flex" justifyContent="flex-end">
                <NewBBPSIcon/>
              </Box>
              <TextField
                select
                fullWidth
                name="instituteLocation"
                label="Institute Location"
                value={selectedLocation}
                onChange={handleLocationChange}
                style={{ marginTop: "20px" }}
              >
                <MenuItem value="">Select Institute Location</MenuItem>
                <MenuItem value="Maharashtra">Maharashtra</MenuItem>
                <MenuItem value="Gujarat">Gujarat</MenuItem>
                {/* Add more states here */}
              </TextField>

              {selectedLocation && (
                <>
                  <TextField
                    select
                    fullWidth
                    name="institute"
                    label="Institute"
                    value={formik.values.institute}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.institute &&
                      Boolean(formik.errors.institute)
                    }
                    helperText={
                      formik.touched.institute && formik.errors.institute
                    }
                    style={{ marginTop: "20px" }}
                  >
                    <MenuItem value="">Select Institute</MenuItem>
                    {instituteData[selectedLocation].map((institute) => (
                      <MenuItem key={institute} value={institute}>
                        {institute}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    fullWidth
                    type="text"
                    label="Student ID"
                    name="studentId"
                    value={formik.values.studentId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.studentId &&
                      Boolean(formik.errors.studentId)
                    }
                    helperText={
                      formik.touched.studentId && formik.errors.studentId
                    }
                    style={{ marginTop: "20px" }}
                  />
                </>
              )}

              <Button
                fullWidth
                type="submit"
                onClick={formik.handleSubmit}
                style={{ 
                  marginTop: "4%", 
                  backgroundColor: '#027BFE', 
                  color: 'white', 
                  textTransform: 'none', 
                  height: inputHeight ,
                  fontSize: 20
                }}
              >
                Confirm
              </Button>
              {submitSuccess && (
                <Typography
                  variant="body2"
                  style={{ color: "green", marginTop: "20px" }}
                >
                  Form successfully submitted!
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
