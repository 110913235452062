import { Button, Container, Grid, MenuItem, Paper, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { NewBBPSIcon, PartnerArrowIcon } from '../../res/NewWebIcons';
import axios from 'axios'; // Import axios for making API requests
import ComplaintInvoice from './ComplaintInvoice';
import ComplaintRegisFail from './ComplaintRegisFail';

const ComplaintRegistration = () => {
    const [typeOfComplaint, setTypeOfComplaint] = useState('');
    const [complaintType, setComplaintType] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [txnReferenceId, setTxnReferenceId] = useState('');
    const [complaintReason, setComplaintReason] = useState('');
    const [complaintDescription, setComplaintDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [transactionHistory, setTransactionHistory] = useState([]);
    const [complaintInvoice, setComplaintInvoice] = useState(false);
    const [complaintFail, setComplaintFail] = useState(false);
    const [invoiceData, setInvoiceData] = useState({});
    const [failData, setFailData] = useState({}); // State for failure data

    // Complaint types array
    const typeOfComplaints = ['Transaction', 'Electricity'];
    const complaintTypes = ['Amount deducted multiple times'];

    // Complaint reasons array
    const complaintReasons = ['Wrong Bill Amount', 'Service Not Working', 'Payment Failed', 'Others'];

    const handleClose = () => {
        setComplaintInvoice(false);
        setComplaintFail(false);
    };

    const handleSubmit = async () => {
        setLoading(true);
        setError(null);
        setSuccessMessage(null);

        try {
            const token = localStorage.getItem("jwtToken");

            const response = await axios.post(
                'http://192.168.31.163:5001/myapp/auth/bbps/complaintRegister',
                {
                    typeOfComplaint,
                    complaintType,
                    mobileNumber,
                    txnReferenceId,
                    complaintReason,
                    complaintDescription
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                    timeout: 300000, // Set timeout to 5 minutes (300,000 milliseconds)
                }
            );

            const currentDate = new Date();
            const formattedDate = currentDate.toLocaleString(); // Get current date and time

            // Handle the response and map the data accordingly
            if (response.data.response.respCode === "1" && response.data.response.status === "SUCCESS") {
                const txnStatus = response.data.response.txnStatusComplainResp;

                setInvoiceData({
                    complaintDateTime: formattedDate,
                    typeOfComplaint,
                    txnReferenceId,
                    complaintId: txnStatus.complaintId || 'N/A',
                    complaintStatus: txnStatus.complaintStatus || 'Assigned',
                    assignedTo: txnStatus.assigned || 'Unknown',
                    responseCode: txnStatus.responseCode || '000',
                    responseReason: txnStatus.responseReason || 'SUCCESS',
                });

                setComplaintInvoice(true); // Show the success invoice popup
            } else {
                const txnFailStatus = response.data.response.txnStatusComplainResp;

                setFailData({
                    referenceId: txnFailStatus.referenceId || 'N/A',
                    responseCode: txnFailStatus.responseCode || 'N/A',
                    responseReason: txnFailStatus.responseReason || 'Failure',
                });

                setComplaintFail(true); // Show failure popup
            }
        } catch (err) {
            if (err.response) {
                setError(`Failed to register complaint: ${err.response.data.message || 'Please try again.'}`);
                setComplaintFail(true); // Show error message
            } else if (err.request) {
                setError('No response received from server. Please check your connection.');
                setComplaintFail(true); // Show error message
            } else {
                setError('Error: ' + err.message);
                setComplaintFail(true); // Show error message
            }
        } finally {
            setLoading(false);
        }
    };

    const handleReset = () => {
        setTypeOfComplaint('');
        setComplaintType('');
        setMobileNumber('');
        setTxnReferenceId('');
        setComplaintReason('');
        setComplaintDescription('');
        setError(null);
        setSuccessMessage(null);
    };

    return (
        <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
            <Grid>
                <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
                    <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '12px' }}>
                        Complaint Management
                    </Typography>
                    <PartnerArrowIcon />
                    <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '12px' }}>
                        Complaint Registration
                    </Typography>
                </Grid>
                <Grid style={{ marginTop: '1%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>
                        Complaint Registration
                    </Typography>
                    <Typography>
                        <NewBBPSIcon />
                    </Typography>
                </Grid>
                <Paper elevation={2} style={{ padding: '2rem', marginTop: '16px' }}>
                    <Grid container spacing={2} style={{ marginTop: '2%' }}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                select
                                label="Type of Complaint"
                                value={typeOfComplaint}
                                onChange={(e) => setTypeOfComplaint(e.target.value)}
                                fullWidth
                                variant="outlined"
                            >
                                {typeOfComplaints.map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Enter Mobile Number"
                                value={mobileNumber}
                                onChange={(e) => setMobileNumber(e.target.value)}
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                select
                                label="Complaints Type"
                                value={complaintType}
                                onChange={(e) => setComplaintType(e.target.value)}
                                fullWidth
                                variant="outlined"
                            >
                                {complaintTypes.map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Transaction ID"
                                value={txnReferenceId}
                                onChange={(e) => setTxnReferenceId(e.target.value)}
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                select
                                label="Reason for Complaint"
                                value={complaintReason}
                                onChange={(e) => setComplaintReason(e.target.value)}
                                fullWidth
                                variant="outlined"
                            >
                                {complaintReasons.map((reason) => (
                                    <MenuItem key={reason} value={reason}>
                                        {reason}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Complaint Description"
                                value={complaintDescription}
                                onChange={(e) => setComplaintDescription(e.target.value)}
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>

                        <Grid container item justifyContent="flex-end" spacing={2} style={{ marginTop: '2%' }}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    {loading ? 'Submitting...' : 'Submit'}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="secondary" onClick={handleReset}>
                                    Reset
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {error && <Typography color="error">{error}</Typography>}
                    {successMessage && <Typography color="primary">{successMessage}</Typography>}
                </Paper>
            </Grid>

            {complaintInvoice && <ComplaintInvoice data={invoiceData} handleClose={handleClose} />}
            {complaintFail && <ComplaintRegisFail data={failData} handleClose={handleClose} />}
        </Container>
    );
};

export default ComplaintRegistration;
