import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

const CreditCardBillPaymentsList = ({ shouldRefresh, pageSize }) => {
  const [recentTrans, setRecentTrans] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [currPage, setCurrentPage] = useState(0); // Current page
  const [isFirstPage, setFirstPage] = useState();
  const [isLastPage, setLastPage] = useState();

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchRecentList(newPage);
  };

  const fetchRecentList = async (nPage) => {
    const jwtTokentopass = localStorage.getItem("jwtToken");

    console.log("current page", nPage);
    const response = await axios.get(
      `https://api.ipaisa.co.in/myapp/creditcard/ccbillpayments`,
      {
        headers: {
          Authorization: `Bearer ${jwtTokentopass}`,
          currentPage: nPage,
          pageSize: pageSize,
        },
      }
    );
    if (response.status === 200) {
      console.log(response);
      console.log("total pages", response.data.totalPages);
      const binData = response.data.content;
      setRecentTrans(binData);
      setTotalPages(response.data.totalPages);
      setFirstPage(response.data.first);
      setLastPage(response.data.last);
    } else {
      console.log(response);
    }
  };

  useEffect(() => {
    fetchRecentList(0);
  }, [shouldRefresh]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead
            style={{
              backgroundColor: "#027BFE",
              color: "white",
            }}
          >
            <TableRow>
              <TableCell
                style={{
                  fontWeight: "bold",
                  color: "white",
                }}
                align="center"
              >
                Sr No
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  color: "white",
                }}
                align="center"
              >
                Date and Time
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  color: "white",
                }}
                align="center"
              >
                Card Number
              </TableCell>

              <TableCell
                style={{
                  fontWeight: "bold",
                  color: "white",
                }}
                align="center"
              >
                Card Network
              </TableCell>

              <TableCell
                style={{
                  fontWeight: "bold",
                  color: "white",
                }}
                align="center"
              >
                Card Bank
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  color: "white",
                }}
                align="center"
              >
                Card Holder Name
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  color: "white",
                }}
                align="center"
              >
                Payment Status
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  color: "white",
                }}
                align="center"
              >
                Bill Amount
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  color: "white",
                }}
                align="center"
              >
                User Name
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  color: "white",
                }}
                align="center"
              >
                User ID
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  color: "white",
                }}
                align="center"
              >
                Order id
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recentTrans && recentTrans.length > 0 ? (
              recentTrans?.map((item, i) => {
                return (
                  <TableRow>
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell align="center">{item.timestamp}</TableCell>
                    <TableCell align="center">{item.payee_account}</TableCell>
                    <TableCell align="center">
                      {item.payee_cardNetwork}
                    </TableCell>
                    <TableCell align="center">{item.payee_bankName}</TableCell>
                    <TableCell align="center">{item.payee_name}</TableCell>
                    <TableCell align="center">
                      {item.status === "Transaction Successful" ? (
                        <span style={{ color: "green" }}>Success</span>
                      ) : (
                        item.status
                      )}
                    </TableCell>
                    <TableCell align="center">{item.txnValue}</TableCell>
                    <TableCell align="center">{item.user.firstName}</TableCell>
                    <TableCell align="center">{item.user.userid}</TableCell>
                    <TableCell align="center">{item.orderid}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={12} align="center">
                  List Not Available
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell colSpan={12} align="center">
                {/* <span>
                    Showing Transactions From {pageFrom} To {pageTo}
                  </span> */}

                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => handlePageChange(currPage - 1)}
                  disabled={isFirstPage}
                >
                  Back
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => handlePageChange(currPage + 1)}
                  disabled={isLastPage}
                  sx={{ marginLeft: 1 }}
                >
                  Next
                </Button>
                <Typography>Total Pages : {totalPages}</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CreditCardBillPaymentsList;
