import React, { useState, useEffect } from "react";
import {
  TextField,
  Container,
  Grid,
  Typography,
  MenuItem,
  Box,
  CardMedia,
  Button,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import BBPSLogo from "../Assets/BBPSLogo/NewBBPS.png";
import logo from "../Assets/login/iPaisaLogo.jpg";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { NewBBPSIcon } from "../../res/NewWebIcons";


export default function RElectricity() {
  const [operators, setOperators] = useState([]);
  const [apiError, setApiError] = useState(null);
  const [billDetails, setBillDetails] = useState({});
  const [billerResponse, setBillerResponse] = useState({});
  const [isResponseReceived, setIsResponseReceived] = useState(false);

  useEffect(() => {
    const fetchOperators = async () => {
      try {
        const response = await axios.get(
          "https://api.ipaisa.co.in/myapp/auth/getallbillers",
          {
            headers: {
              AUTH: "ilYUh4RGm4hMjihwzFUsna1aXPHEy9Wn6w/eNlcUuv0=",
            },
          }
        );

        if (response.data.biller && Array.isArray(response.data.biller)) {
          setOperators(response.data.biller);
        } else {
          setOperators([]);
        }
      } catch (error) {
        setApiError("Failed to load operators. Please try again later.");
        setOperators([]);
      }
    };

    fetchOperators();
  }, []);

  const formik = useFormik({
    initialValues: {
      consumerNumber: "",
      operator: "",
    },
    validationSchema: Yup.object({
      consumerNumber: Yup.string()
        .matches(/^[0-9]{12}$/, "Please enter a valid 12-digit consumer number")
        .required("Consumer number is required"),
      operator: Yup.string().required("Operator is required"),
    }),
    onSubmit: async (values) => {
      if (!values.operator || !values.consumerNumber) {
        return;
      }

      try {
        const response = await axios.get(
          "https://api.ipaisa.co.in/myapp/auth/axisobj",
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const billDetails = response.data.response.billDetails.reduce(
          (acc, item) => {
            acc[item.name] = item.value;
            return acc;
          },
          {}
        );

        setBillDetails(billDetails);
        setBillerResponse(response.data.response.billerResponse);
        setIsResponseReceived(true);
      } catch (error) {
        console.error("There was a problem with the axios operation:", error);
      }
    },
  });

  const handlePay = async () => {
    try {
      const response = await axios.get(
        'https://api.ipaisa.co.in/myapp/auth/axixpay/EV3DV3DEFID5Z3DFA97XVG3JQML42471559',
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      
      console.log('Payment API response:', response.data);

      if (response.data.status === "FAILURE") {
        toast.error(response.data.response.responseReason);
      } else {
        toast.success(response.data.response.responseReason);
      }
    } catch (error) {
      console.error("There was a problem with the payment operation:", error);
      toast.error('Payment failed. Please try again.');
    }
  };

  return (
    <Container maxWidth="lg" style={{ marginTop: "12%", marginBottom: "3%" }}>
      <Box
        mt={4}
        p={4}
        boxShadow={2}
        borderRadius={8}
        sx={{
          background: "linear-gradient(135deg, #0B1E59 0%, #1840BF 100%)",
          color: "white",
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} style={{ marginTop: "1%" }}>
            <CardMedia
              component="img"
              image={logo}
              alt="iPaisa Logo"
              style={{
                backgroundColor: "transparent",
                borderRadius: "50%",
                marginBottom: "4%",
              }}
              sx={{
                height: { xs: 50, md: 90 },
                width: { xs: 50, md: 90 },
                mt: { xs: 1, md: 1 },
                marginRight: { xs: "10px", md: "10px" },
              }}
            />
            <Box alignItems="center" mb={2}>
              <Typography variant="h4" sx={{ fontWeight: "bold", fontSize: "35px" }}>
                Ingenious Electricity Bill Settlement
              </Typography>
            </Box>
            <Typography variant="body1" paragraph sx={{ fontSize: "20px", marginTop: "3%" }}>
              Empower your life with the efficient management of electricity bills, ensuring uninterrupted power supply for your world of possibilities.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box p={4} bgcolor="white" boxShadow={2} borderRadius={8}>
              <Typography variant="h4" color="#027BFE" sx={{ fontWeight: "bold", fontSize: "30px" }}>
                Electricity Bill Payment
              </Typography>
              <Box display="flex" justifyContent="flex-end">
                <NewBBPSIcon/>
              </Box>
              {apiError && (
                <Typography variant="body2" color="error" style={{ marginBottom: "20px" }}>
                  {apiError}
                </Typography>
              )}

              {/* Form Fields */}
              <TextField
                select
                fullWidth
                name="operator"
                label="Operator"
                value={formik.values.operator}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.operator && Boolean(formik.errors.operator)}
                helperText={formik.touched.operator && formik.errors.operator}
                style={{ marginTop: "20px" }}
                disabled={isResponseReceived}
              >
                <MenuItem value="">Select Operator</MenuItem>
                {operators.length > 0
                  ? operators.map((operator, index) => (
                      <MenuItem key={index} value={operator.billerId}>
                        {operator.billerName}
                      </MenuItem>
                    ))
                  : <MenuItem disabled>No operators available</MenuItem>}
              </TextField>

              <TextField
                fullWidth
                type="text"
                label="Consumer Number"
                name="consumerNumber"
                value={formik.values.consumerNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.consumerNumber && Boolean(formik.errors.consumerNumber)}
                helperText={formik.touched.consumerNumber && formik.errors.consumerNumber}
                inputProps={{
                  maxLength: 12,
                  pattern: "[0-9]*",
                  title: "Please enter a 12-digit number",
                }}
                style={{ marginTop: "20px" }}
                disabled={isResponseReceived}
              />

              {!isResponseReceived ? (
                <Box display="flex" justifyContent="space-between" mt={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    style={{
                      marginRight: "10px",
                      backgroundColor: '#FF0000',
                      color: '#FFFFFF',
                      textTransform: "none",
                      height: "40px",
                    }}
                    onClick={() => formik.resetForm()}
                  >
                    Cancel
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#027BFE",
                      color: "white",
                      fontWeight: "bold",
                      textTransform: "none",
                      height: "40px",
                    }}
                    onClick={formik.handleSubmit}
                  >
                    Submit
                  </Button>
                </Box>
              ) : (
                <>
                  {Object.keys(billDetails).length > 0 && (
                    <>
                      <TextField
                        fullWidth
                        label="Consumer No"
                        value={billDetails["Consumer No"] || ""}
                        InputProps={{ readOnly: true }}
                        style={{ marginTop: "20px" }}
                      />
                      <TextField
                        fullWidth
                        label="BU"
                        value={billDetails["BU"] || ""}
                        InputProps={{ readOnly: true }}
                        style={{ marginTop: "20px" }}
                      />
                      <TextField
                        fullWidth
                        label="Customer Name"
                        value={billerResponse.customerName || ""}
                        InputProps={{ readOnly: true }}
                        style={{ marginTop: "20px" }}
                      />
                      <TextField
                        fullWidth
                        label="Amount"
                        value={billerResponse.amount || ""}
                        InputProps={{ readOnly: true }}
                        style={{ marginTop: "20px" }}
                      />
                      <TextField
                        fullWidth
                        label="Bill Date"
                        value={billerResponse.billDate || ""}
                        InputProps={{ readOnly: true }}
                        style={{ marginTop: "20px" }}
                      />
                      <TextField
                        fullWidth
                        label="Due Date"
                        value={billerResponse.dueDate || ""}
                        InputProps={{ readOnly: true }}
                        style={{ marginTop: "20px" }}
                      />
                      <TextField
                        fullWidth
                        label="Bill Number"
                        value={billerResponse.billNumber || ""}
                        InputProps={{ readOnly: true }}
                        style={{ marginTop: "20px" }}
                      />
                    </>
                  )}

                  <Box display="flex" justifyContent="space-between" mt={2}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      style={{
                        marginRight: "10px",
                        backgroundColor: '#FF0000',
                        color: '#FFFFFF',
                        textTransform: "none",
                        height: "40px",
                      }}
                      onClick={() => {
                        setIsResponseReceived(false);
                        formik.resetForm();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      style={{
                        backgroundColor: "#027BFE",
                        color: "white",
                        fontWeight: "bold",
                        textTransform: "none",
                        height: "40px",
                      }}
                      onClick={handlePay}
                    >
                      Pay
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
