import React from 'react'
import { Button, Grid, Paper, Typography, Dialog } from '@mui/material'
import { FailedPopIcon } from '../../res/icons'
import CloseIcon from '@mui/icons-material/Close';


const PayoutFailedPopup = ({  handleClose, errorMessage }) => {
  return (
    <Dialog open={true} onClose={handleClose} errorMessage={errorMessage}>
        <Grid style={{ padding: '2rem' }}>
            <Grid>
            <Grid style={{display:'flex', justifyContent:'flex-end'}}>
            <CloseIcon style={{cursor:'pointer'}} onClick={handleClose}/>
            </Grid>
            <Grid style={{display:'flex', justifyContent:'center', }}>
                <FailedPopIcon/>
            </Grid>
            <Grid style={{display:'flex', justifyContent:'center', marginTop:'6%'}}>
                <Typography style={{fontWeight:'bold', fontSize:'20px'}}>Transaction Failed!</Typography>
            </Grid>
            <Grid style={{display:'flex', justifyContent:'center', marginTop:'6%'}}>
                <Typography color='textSecondary' style={{fontSize:'15px', textAlign:'center'}}>
                We regret to inform you that your Payout could not be completed.
                </Typography>
            </Grid>
            <Grid style={{display:'flex', justifyContent:'center', marginTop:'6%', marginBottom:'4%'}}>
                <Button style={{color:'white', backgroundColor:'#185ABD', width:'20%'}}  onClick={handleClose}>
                    OK
                </Button>
            </Grid>
            </Grid>
        </Grid>
    </Dialog>
  )
}

export default PayoutFailedPopup
