// import React, { useState } from "react";
// import {
//   Button,
//   TextField,
//   Container,
//   Grid,
//   Typography,
//   Box,
//   MenuItem,
//   CardMedia,
// } from "@mui/material";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import BBPSLogo from "../Assets/BBPSLogo/NewBBPS.png";
// import logo from "../Assets/login/iPaisaLogo.jpg";

// export default function RetailerMetro() {
//   const [submitSuccess, setSubmitSuccess] = useState(false);
//   const [showTicketType, setShowTicketType] = useState(false);
//   const [showSmartcardFields, setShowSmartcardFields] = useState(false);

//   const formik = useFormik({
//     initialValues: {
//       operator: "",
//       ticketType: "",
//       cardNumber: "",
//       amount: "",
//       consumerNumber: "",
//     },
//     validationSchema: Yup.object({
//       operator: Yup.string().required("Operator is required"),
//       ticketType: Yup.string().when('operator', {
//         is: (value) => value !== "",
//         then: Yup.string().required("Ticket type is required"),
//       }),
//       cardNumber: Yup.string().when('ticketType', {
//         is: 'Smartcard recharge',
//         then: Yup.string().required("Card number is required"),
//       }),
//       amount: Yup.string().when('ticketType', {
//         is: 'Smartcard recharge',
//         then: Yup.string().required("Amount is required"),
//       }),
//       consumerNumber: Yup.string().required("VRN Number is required"),
//     }),
//     onSubmit: (values) => {
//       // Perform form submission logic here
//       setSubmitSuccess(true);
//     },
//   });

//   const handleOperatorChange = (event) => {
//     formik.handleChange(event);
//     if (event.target.value) {
//       setShowTicketType(true);
//       setShowSmartcardFields(false);
//     } else {
//       setShowTicketType(false);
//       setShowSmartcardFields(false);
//     }
//   };

//   const handleTicketTypeChange = (event) => {
//     formik.handleChange(event);
//     if (event.target.value === "Smartcard recharge") {
//       setShowSmartcardFields(true);
//     } else {
//       setShowSmartcardFields(false);
//     }
//   };

//   const inputHeight = "56px";

//   return (
//     <Container maxWidth="lg" style={{ marginTop: "12%", marginBottom: "3%" }}>
//       <Box
//         mt={4}
//         p={4}
//         boxShadow={2}
//         bgcolor="#DDF1F5"
//         borderRadius={8}
//         sx={{
//           background: "linear-gradient(135deg, #0B1E59 0%, #1840BF 100%)",
//           color: "white",
//         }}
//       >
//         <Grid container spacing={4}>
//           <Grid item xs={12} sm={6} style={{ marginTop: "1%" }}>
//             <CardMedia
//               component="img"
//               image={logo}
//               alt="iPaisa Logo"
//               style={{
//                 backgroundColor: "transparent",
//                 borderRadius: "50%",
//                 marginBottom: "4%",
//               }}
//               sx={{
//                 height: { xs: 50, md: 90 },
//                 width: { xs: 50, md: 90 },
//                 mt: { xs: 1, md: 1 },
//                 marginRight: { xs: "10px", md: "10px" }, // Adjusted to ensure space between logo and text
//               }}
//             />
//             <Typography
//               variant="h4"
//               sx={{ fontWeight: "bold", fontSize: "35px" }}
//             >
//               Online Metro
//             </Typography>
//             <Typography
//               variant="body1"
//               paragraph
//               sx={{ fontSize: "20px", marginTop: "5%" }}
//             >
//               Streamline your commute with seamless online metro card recharges,
//               ensuring smooth and hassle-free travel every day
//             </Typography>
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <Box p={4} bgcolor="white" boxShadow={2} borderRadius={8}>
//               <Typography
//                 variant="h4"
//                 color="#027BFE"
//                 sx={{ fontWeight: "bold", fontSize: "30px" }}
//               >
//                 Online Metro
//               </Typography>
//               <Box display="flex" justifyContent="flex-end">
//                 <img
//                   src={BBPSLogo}
//                   alt="BBPS Logo"
//                   style={{
//                     marginLeft: "25.5rem",
//                     width: "13%",
//                     height: "20%",
//                   }}
//                 />
//               </Box>
//               <TextField
//                 select
//                 fullWidth
//                 name="operator"
//                 label="Operator"
//                 value={formik.values.operator}
//                 onChange={handleOperatorChange}
//                 onBlur={formik.handleBlur}
//                 error={formik.touched.operator && Boolean(formik.errors.operator)}
//                 helperText={formik.touched.operator && formik.errors.operator}
//                 style={{ marginTop: "20px" }}
//               >
//                 <MenuItem value="">Select Operator</MenuItem>
//                 <MenuItem value="Delhi Metro">Delhi Metro</MenuItem>
//                 <MenuItem value="Mumbai Metro">Mumbai Metro</MenuItem>
//                 <MenuItem value="Chennai Metro">Chennai Metro</MenuItem>
//                 <MenuItem value="Hyderabad Metro">Hyderabad Metro</MenuItem>
//                 <MenuItem value="Kolkata Metro">Kolkata Metro</MenuItem>
//               </TextField>

//               {showTicketType && (
//                 <TextField
//                   select
//                   fullWidth
//                   name="ticketType"
//                   label="Ticket Type"
//                   value={formik.values.ticketType}
//                   onChange={handleTicketTypeChange}
//                   onBlur={formik.handleBlur}
//                   error={formik.touched.ticketType && Boolean(formik.errors.ticketType)}
//                   helperText={formik.touched.ticketType && formik.errors.ticketType}
//                   style={{ marginTop: "20px" }}
//                 >
//                   <MenuItem value="">Select Ticket Type</MenuItem>
//                   <MenuItem value="Smartcard recharge">Smartcard recharge</MenuItem>
//                 </TextField>
//               )}

//               {showSmartcardFields && (
//                 <>
//                   <TextField
//                     fullWidth
//                     type="text"
//                     label="Card Number"
//                     name="cardNumber"
//                     value={formik.values.cardNumber}
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     error={
//                       formik.touched.cardNumber && Boolean(formik.errors.cardNumber)
//                     }
//                     helperText={
//                       formik.touched.cardNumber && formik.errors.cardNumber
//                     }
//                     style={{ marginTop: "20px" }}
//                   />

//                   <TextField
//                     fullWidth
//                     type="number"
//                     label="Amount"
//                     name="amount"
//                     value={formik.values.amount}
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     error={formik.touched.amount && Boolean(formik.errors.amount)}
//                     helperText={formik.touched.amount && formik.errors.amount}
//                     style={{ marginTop: "20px" }}
//                   />
//                 </>
//               )}

//               <TextField
//                 fullWidth
//                 type="text"
//                 label="VRN Number"
//                 name="consumerNumber"
//                 value={formik.values.consumerNumber}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 error={
//                   formik.touched.consumerNumber &&
//                   Boolean(formik.errors.consumerNumber)
//                 }
//                 helperText={
//                   formik.touched.consumerNumber &&
//                   formik.errors.consumerNumber
//                 }
//                 style={{ marginTop: "20px" }}
//               />

//               <Button
//                 fullWidth
//                 type="submit"
//                 onClick={formik.handleSubmit}
//                 style={{
//                   marginTop: "4%",
//                   backgroundColor: "#027BFE",
//                   color: "white",
//                   textTransform: "none",
//                   height: inputHeight,
//                   fontSize: 20,
//                 }}
//               >
//                 Proceed
//               </Button>

//               {submitSuccess && (
//                 <Typography
//                   variant="body2"
//                   style={{ color: "green", marginTop: "20px" }}
//                 >
//                   Bill successfully submitted!
//                 </Typography>
//               )}
//             </Box>
//           </Grid>
//         </Grid>
//       </Box>
//     </Container>
//   );
// }

import React, { useState } from "react";
import {
  Button,
  TextField,
  Container,
  Grid,
  Typography,
  Box,
  MenuItem,
  CardMedia,
} from "@mui/material";
import BBPSLogo from "../Assets/BBPSLogo/NewBBPS.png";
import logo from "../Assets/login/iPaisaLogo.jpg";
import { NewBBPSIcon } from "../../res/NewWebIcons";

export default function RetailerMetro() {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    operator: "",
    ticketType: "",
    cardNumber: "",
    amount: "",
  });

  const validate = () => {
    const newErrors = {};
    if (!values.operator) newErrors.operator = "Operator is required";
    if (!values.ticketType) newErrors.ticketType = "Ticket Type is required";
    if (values.ticketType === "Smartcard recharge" && !values.cardNumber) {
      newErrors.cardNumber = "Card Number is required";
    }
    if (values.ticketType === "Smartcard recharge" && !values.amount) {
      newErrors.amount = "Amount is required";
    }
    return newErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      // Perform form submission logic here
      setSubmitSuccess(true);
    }
  };

  const inputHeight = "56px";

  return (
    <Container maxWidth="lg" style={{ marginTop: "12%", marginBottom: "3%" }}>
      <Box
        mt={4}
        p={4}
        boxShadow={2}
        bgcolor="#DDF1F5"
        borderRadius={8}
        sx={{
          background: "linear-gradient(135deg, #0B1E59 0%, #1840BF 100%)",
          color: "white",
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} style={{ marginTop: "1%" }}>
            <CardMedia
              component="img"
              image={logo}
              alt="iPaisa Logo"
              style={{
                backgroundColor: "transparent",
                borderRadius: "50%",
                marginBottom: "4%",
              }}
              sx={{
                height: { xs: 50, md: 90 },
                width: { xs: 50, md: 90 },
                mt: { xs: 1, md: 1 },
                marginRight: { xs: "10px", md: "10px" }, // Adjusted to ensure space between logo and text
              }}
            />
            <Typography variant="h4" sx={{ fontWeight: "bold", fontSize: "35px" }}>
              Online Metro
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: "20px", marginTop: "5%" }}>
              Streamline your commute with seamless online metro card recharges, ensuring smooth and hassle-free travel every day.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box p={4} bgcolor="white" boxShadow={2} borderRadius={8}>
              <Typography
                variant="h4"
                color="#027BFE"
                sx={{ fontWeight: "bold", fontSize: "30px" }}
              >
                Online Metro
              </Typography>
              <Box display="flex" justifyContent="flex-end">
                <NewBBPSIcon/>
              </Box>
              <form onSubmit={handleSubmit}>
                <TextField
                  select
                  fullWidth
                  name="operator"
                  label="Operator"
                  value={values.operator}
                  onChange={handleChange}
                  error={!!errors.operator}
                  helperText={errors.operator}
                  style={{ marginTop: "20px" }}
                >
                  <MenuItem value="">Select Operator</MenuItem>
                  <MenuItem value="Delhi Metro">Delhi Metro</MenuItem>
                  <MenuItem value="Mumbai Metro">Mumbai Metro</MenuItem>
                  <MenuItem value="Chennai Metro">Chennai Metro</MenuItem>
                  <MenuItem value="Hyderabad Metro">Hyderabad Metro</MenuItem>
                  <MenuItem value="Kolkata Metro">Kolkata Metro</MenuItem>
                </TextField>

                {values.operator && (
                  <TextField
                    select
                    fullWidth
                    name="ticketType"
                    label="Ticket Type"
                    value={values.ticketType}
                    onChange={handleChange}
                    error={!!errors.ticketType}
                    helperText={errors.ticketType}
                    style={{ marginTop: "20px" }}
                  >
                    <MenuItem value="">Select Ticket Type</MenuItem>
                    <MenuItem value="Smartcard recharge">Smartcard recharge</MenuItem>
                  </TextField>
                )}

                {values.ticketType === "Smartcard recharge" && (
                  <>
                    <TextField
                      fullWidth
                      name="cardNumber"
                      label="Card Number"
                      value={values.cardNumber}
                      onChange={handleChange}
                      error={!!errors.cardNumber}
                      helperText={errors.cardNumber}
                      style={{ marginTop: "20px" }}
                    />
                    <TextField
                      fullWidth
                      name="amount"
                      label="Amount"
                      type="number"
                      value={values.amount}
                      onChange={handleChange}
                      error={!!errors.amount}
                      helperText={errors.amount}
                      style={{ marginTop: "20px" }}
                    />
                  </>
                )}

                <Button
                  fullWidth
                  type="submit"
                  style={{
                    marginTop: "4%",
                    backgroundColor: "#027BFE",
                    color: "white",
                    textTransform: "none",
                    height: inputHeight,
                    fontSize: 20,
                  }}
                >
                  Proceed
                </Button>
                {submitSuccess && (
                  <Typography
                    variant="body2"
                    style={{ color: "green", marginTop: "20px" }}
                  >
                    Bill successfully submitted!
                  </Typography>
                )}
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
