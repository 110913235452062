import React, { useState, useEffect } from 'react';
import {
    Button,
    Container,
    Grid,
    CircularProgress,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    TextField,
    Box,
    Typography,
    InputBase,
    IconButton,
    Pagination,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchIcon from "@mui/icons-material/Search";
import axios from 'axios';
import { toast } from 'react-toastify';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import iPaisaLogo from '../Assets/iPaisaLogo/iPaisaLogo.jpg';
import { DownloadIcon, PartnerArrowIcon } from '../../res/NewWebIcons';
import DownloadIcon1 from "@mui/icons-material/Download";
import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf.js';
import PayoutReportInvoice from './PayoutReportInvoice';
import PayinReportInvoice from './PayinReportInvoice';


const useStyles = makeStyles(() => ({
    evenRow: {
        backgroundColor: '#D0EFFF',
    },
    oddRow: {
        backgroundColor: '#FFFFFF',
    },
}));

const NewPayIn = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [payInData, setPayInData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const classes = useStyles();

    useEffect(() => {
        fetchPayInData();
    }, []);

    const fetchPayInData = async () => {
        const jwtToken = localStorage.getItem('jwtToken');
        try {
            const response = await axios.get('https://api.ipaisa.co.in/myapp/auth/easTxnDetails', {
                headers: { Authorization: `Bearer ${jwtToken}` }
            });
            const sortedData = (response.data.body || []).sort((a, b) => new Date(b.addedon) - new Date(a.addedon));
            setPayInData(sortedData);
            setFilteredData(sortedData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching PayIn data:', error);
            setLoading(false);
        }
    };

    const formatDate = (dateTimeString) => {
        if (!dateTimeString) return '';
        const [datePart] = dateTimeString.split('T');
        const [year, month, day] = datePart.split('-');
        return `${day}-${month}-${year}`;
    };

    const exportCSV = () => {
        if (!filteredData || filteredData.length === 0) {
            toast('No transaction data available to generate CSV.');
            return;
        }
        
        // Sort filteredData before exporting
        const sortedFilteredData = [...filteredData].sort((a, b) => new Date(b.addedon) - new Date(a.addedon));
        
        const headers = [
            'Sr No.', 'Transaction ID', 'UTR No.', 'Status', 'Date', 'Amount'
        ];

        const csvRows = [];
        csvRows.push(headers.join(','));
        sortedFilteredData.forEach((row, index) => {
            const values = [
                index + 1,
                row.txnid,
                row.easepayid,
                row.status,
                formatDate(row.addedon),
                row.amount,
            ];
            csvRows.push(values.join(','));
        });

        const csvContent = csvRows.join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', 'PayInData.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    const handleDownloadPDF = async (data) => {
        const receiptElement = document.getElementById(`receipt-${data.txnid}`);
        
        if (!receiptElement) {
            console.error('Receipt element not found:', `receipt-${data.txnid}`);
            return;
        }
    
        const opt = {
            margin:       [0.5, 0.5, 0.5, 0.5], // top, left, bottom, right in inches
            filename:     `receipt_${data.txnid}.pdf`,
            image:        { type: 'jpeg', quality: 0.98 },
            html2canvas:  { scale: 2 },
            jsPDF:        { unit: 'in', orientation: 'landscape', format: 'a4', margin: 0.5 }
        };
    
        try {
            await html2pdf().from(receiptElement).set(opt).save();
        } catch (error) {
            console.error('Error generating PDF: ', error);
        }
    };

    const generatePDF = () => {
        if (!filteredData || filteredData.length === 0) {
            toast('No transaction data available to generate PDF.');
            return;
        }

        const sortedFilteredData = [...filteredData].sort((a, b) => new Date(b.addedon) - new Date(a.addedon));

        const doc = new jsPDF();
        const logo = new Image();
        logo.src = iPaisaLogo;

        logo.onload = () => {
            doc.addImage(logo, 'PNG', 10, 10, 30, 30);
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(16);
            doc.text('Transaction Statement', 50, 20);
            doc.setFontSize(12);
            doc.text('Filtered Transaction Report', 50, 30);

            const tableColumn = ["ID", "Transaction ID", "UTR No.", "Status", "Date", "Amount"];
            const tableRows = [];

            sortedFilteredData.forEach((row, index) => {
                const rowData = [
                    index + 1,
                    row.txnid,
                    row.easepayid,
                    row.status,
                    formatDate(row.addedon),
                    `${row.amount}`
                ];
                tableRows.push(rowData);
            });

            doc.autoTable({
                head: [tableColumn],
                body: tableRows,
                startY: 50,
                styles: { fontSize: 10, cellPadding: 3, overflow: 'linebreak' },
                headStyles: { fillColor: [22, 160, 133], textColor: [255, 255, 255], fontSize: 12 },
            });

            doc.save('PayIn Report.pdf');
        };
    };

    const handleSearch = () => {
        let filtered = payInData;

        // Filter by search query
        if (searchQuery) {
            filtered = filtered.filter(txn =>
                txn.txnid.includes(searchQuery) ||
                txn.easepayid.includes(searchQuery) ||
                txn.status.includes(searchQuery)
            );
        }

        // Filter by date range
        if (fromDate && toDate) {
            filtered = filtered.filter(txn => {
                const txnDate = new Date(txn.addedon);
                return txnDate >= new Date(fromDate) && txnDate <= new Date(toDate + 'T23:59:59');
            });
        }

        // Sort the filtered results
        filtered.sort((a, b) => new Date(b.addedon) - new Date(a.addedon));

        setFilteredData(filtered);
        setCurrentPage(1); // Reset to first page on search
    };

    // Pagination logic
    const indexOfLastItem = currentPage * rowsPerPage;
    const indexOfFirstItem = indexOfLastItem - rowsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    if (loading) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
                <CircularProgress />
            </Grid>
        );
    }

    const handleClear = () => {
        setSearchQuery('');
        setFromDate('');
        setToDate('');
        setFilteredData(payInData); // Reset filtered data to the original payInData
        setCurrentPage(1); // Reset to first page
    };

    return (
        <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
            <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Report</Typography>
                <PartnerArrowIcon />
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Pay In Report</Typography>
            </Grid>

            <Grid>
                <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1%' }}>
                    <Grid>
                        <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>Pay In Report</Typography>
                    </Grid>
                    <Grid style={{ display: 'flex', gap: '1rem' }}>
                        <Button
                            style={{
                                textTransform: 'none',
                                backgroundColor: '#027BFE',
                                color: '#FFFFFF',
                                borderRadius: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '8px 16px',
                                fontFamily: 'sans-serif',
                                fontSize: '14px',
                                whiteSpace: 'nowrap', // Ensure text stays in one line
                                gap: '5%',
                                height: '40px',
                            }}
                            onClick={exportCSV}
                        >
                            <DownloadIcon />
                            Download CSV File
                        </Button>
                        <Button
                            style={{
                                textTransform: 'none',
                                backgroundColor: '#027BFE',
                                color: '#FFFFFF',
                                borderRadius: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '8px 16px',
                                fontFamily: 'sans-serif',
                                fontSize: '14px',
                                whiteSpace: 'nowrap', // Ensure text stays in one line
                                gap: '5%',
                                height: '40px',
                            }}
                            onClick={generatePDF}
                        >
                            <DownloadIcon />
                            Download PDF File
                        </Button>
                    </Grid>
                </Grid>
            </Grid>


            <Paper elevation={2} sx={{ p: 3, borderRadius: 2, marginTop: '2%' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            type="date"
                            label="From Date"
                            value={fromDate}
                            onChange={(e) => setFromDate(e.target.value)}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            type="date"
                            label="To Date"
                            value={toDate}
                            onChange={(e) => setToDate(e.target.value)}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={12} style={{marginTop:'3%'}}>
                        <Box display="flex" justifyContent="flex-end" alignItems="center" gap={2}>
                        <Button
                        onClick={handleSearch} 
                        sx={{
                              backgroundColor: '#027BFE',
                              color: 'white',
                              height: '56px',
                              textTransform: 'none',
                              borderRadius: '10px',
                              width: '120px',
                            }}>
                            Search
                        </Button>
                        <Button
                            onClick={() => handleClear()}
                            sx={{
                              backgroundColor: 'gray',
                              color: 'white',
                              height: '56px',
                              textTransform: 'none',
                              borderRadius: '10px',
                              width: '120px',
                            }}
                          >
                            Clear
                        </Button>
                        </Box>
                    </Grid>
                    
                </Grid>
            </Paper>

            <Grid style={{ marginTop: '3%' }}>
                <Grid
                    style={{
                        display: 'flex',
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        maxWidth: '20%',
                        borderRadius: 10,
                        border: '1px solid rgba(0, 0, 0, 0.3)',
                        padding: '1%',
                        justifyContent: 'center',
                        marginBottom: '2%',
                    }}
                >
                    <InputBase
                        placeholder="Search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSearch(); // Trigger search when Enter is pressed
                            }
                        }}
                        fullWidth
                        style={{ paddingLeft: '3%' }}
                    />
                    <IconButton backgroundColor='textSecondary'
                        onClick={handleSearch}
                    >
                        <SearchIcon />
                    </IconButton>
                </Grid>

                <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto', borderRadius: '12px 12px 0px 0px' }}>
                    <Table>
                        <TableHead style={{ backgroundColor: '#027BFE', color: 'white' }}>
                            <TableRow>
                                <TableCell style={{ color: 'white' }} align="start">Sr. No.</TableCell>
                                <TableCell style={{ color: 'white' }} align="start">Txn ID</TableCell>
                                <TableCell style={{ color: 'white' }} align="start">UTR No</TableCell>
                                <TableCell style={{ color: 'white' }} align="start">Date</TableCell>
                                <TableCell style={{ color: 'white' }} align="start">Amount</TableCell>
                                <TableCell style={{ color: 'white' }} align="start">Status</TableCell>
                                <TableCell style={{ color: 'white' }} align="center">Invoice</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {currentItems.length > 0 ? (
                                currentItems.map((txn, index) => (
                                    <TableRow key={txn.id} className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                                        <TableCell align="start">{indexOfFirstItem + index + 1}</TableCell>
                                        <TableCell align="start">{txn.txnid}</TableCell>
                                        <TableCell align="start">{txn.easepayid}</TableCell>
                                        <TableCell align="start">{formatDate(txn.addedon)}</TableCell> {/* Correctly call formatDate */}
                                        <TableCell align="start">{txn.amount}</TableCell>
                                        <TableCell align="start" style={{
                                    color: txn.status === 'success' ? 'green' 
                                        : txn.status === 'failure' ? 'red' 
                                        : 'inherit', // Default text color
                                    fontWeight: 'bold',
                                }}>
                                    {
                                    txn.status === 'success' ? 'Successful'
                                    : txn.status === 'failure' ? 'Failed' 
                                    : txn.status || 'N/A'} {/* Default value if no match */}
                                        </TableCell>  

                                <TableCell align="center">
                                        <DownloadIcon1
                                            onClick={() => handleDownloadPDF(txn)} // Trigger PDF download
                                            style={{ cursor: 'pointer' }}
                                        />
                                </TableCell>
                                <Grid style={{ position: 'absolute', left: '-9999px', top: '0px' }}>
                                        <Grid id={`receipt-${txn.txnid}`}>
                                            <PayinReportInvoice data={txn} />
                                        </Grid>
                                </Grid>

                                    </TableRow>
                                ))
                            ) : (
                            <TableRow>
                                <TableCell colSpan={16} align="center">No Data Available</TableCell>
                            </TableRow>
                            )}
                        </TableBody>

                    </Table>
                </TableContainer>
                <Pagination
                    count={Math.ceil(filteredData.length / rowsPerPage)} // Total pages based on filtered data
                    page={currentPage} // Current page
                    onChange={(event, newPage) => {
                        setCurrentPage(newPage);
                        window.scrollTo(0, 0); // Optional: scroll to top on page change
                    }} // Handle page change
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    style={{ marginTop: '1rem', display: 'flex', justifyContent: 'center' }}
                />
            </Grid>
        </Container>
    );
};

export default NewPayIn;
