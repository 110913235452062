import { Grid, Container, Paper } from '@material-ui/core'
import { Button, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import { PrintIcon } from '../../res/NewWebIcons'

const ReportsInvoice = () => {
  return (

    <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
        <Grid>
            <Grid style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                <Typography style={{fontWeight:'bold', fontSize:'38px'}}>iPaisa</Typography>
                <Typography style={{fontSize:'18px', fontWeight:'bold'}}>EDSOM FINTECH PRIVATE LIMITED</Typography>
            </Grid>
            <Grid style={{display:'flex',  alignItems:'center', justifyContent:'space-between'}}>
                <Grid style={{marginTop:'1%'}}>
                    <Typography style={{fontSize:'14px', fontWeight:'bold', textAlign:"start"}}>08 Oct 2024</Typography>
                    <Typography style={{fontSize:'14px', fontWeight:'bold', textAlign:"start"}}>14:46 PM</Typography>
                </Grid>
                <Grid>
                    <Typography style={{fontSize:'14px', textAlign:"end"}}>201, BANER ROAD, PUNE, MAHARASHTRA - 411045</Typography>
                    <Typography style={{fontSize:'14px', textAlign:"end"}}>edsomfintech@gmail.com</Typography>
                    <Typography style={{fontSize:'14px', textAlign:"end", fontWeight:'bold'}}>MID : 668680</Typography>
                </Grid>
            </Grid>
            <TableContainer style={{ marginTop: '3%' }}>
                <Table aria-label="transaction table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{fontWeight:"bold"}}>Txn ID</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>UTR No.</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Payment Type</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Payment By</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Amount</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>iPaisa1728380611026</TableCell>
                            <TableCell>E2410080AT6OT1</TableCell>
                            <TableCell>UPI</TableCell>
                            <TableCell>8177819808@ptaxis</TableCell>
                            <TableCell>120.00</TableCell>
                            <TableCell>Successful</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <Grid style={{marginTop:'4%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                <Grid>
                    <Typography style={{fontWeight:'bold', fontSize:'24px'}}>Thank you for transacting at iPaisa!</Typography>
                    <Typography color='textSecondary'>This is a system generated receipt hence does not require any signature.</Typography>
                    <Typography color='textSecondary'>Queries? Write to care@ipaisa.com</Typography>
                </Grid>
                <Grid>
                    <Divider style={{width:'100%'}}/>
                    <Grid>
                        <Typography>Total Amount : </Typography>
                        <Typography>Grand Total : </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid style={{marginTop:'4%', display:'flex', justifyContent:'center'}}>
                <Button variant='outlined' sx={{borderRadius:'8px', textTransform:'none', fontSize:'18px', gap:'4%', color:'black'}}>Print <PrintIcon/></Button>
            </Grid>
        </Grid>
    </Container>
   
  )
}

export default ReportsInvoice
